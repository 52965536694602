.ui-datepicker {
    background-color: #fff;
    padding:6px;
    border: 1px solid #ddd;
    border-radius: 2px;
    color: #555;
    display: none;
    font-size: 14px;
    font-family: sans-serif;
    box-shadow: 0 1px 8px rgba(0,0,0,.2);
    cursor: default;
}

.ui-datepicker-header {
    position: relative;
    height: 42px;
    line-height: 42px;
    text-align: center;
    margin-bottom: 6px;
}

.ui-datepicker-header .ui-icon {
    display: none;
}

.ui-datepicker-prev,
.ui-datepicker-next {
    position: absolute;
    background-color: transparent;
    display: block;
    width: 30px;
    height: 42px;
}

.ui-datepicker-prev {
    top: 0;
    left: 0;
}

.ui-datepicker-prev:after {
    content: '';
    display: block;
    border-width: 6px 6px 6px 0;
    border-style: solid;
    border-color: transparent #888 transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -6px;
    margin-left: -4px;
}

.ui-datepicker-prev.ui-state-hover:after {
    border-right-color: #DDC000;
}

.ui-datepicker-next {
    top: 0;
    right: 0;
}

.ui-datepicker-next:after {
    content: '';
    display: block;
    border-width: 6px 0 6px 6px;
    border-style: solid;
    border-color: transparent transparent transparent #888;
    position: absolute;
    top: 50%;
    right: 50%;
    margin-top: -6px;
    margin-right: -4px;
}

.ui-datepicker-next.ui-state-hover:after {
    border-left-color: #DDC000;
}

.ui-datepicker-prev.ui-state-disabled:after {
    border-right-color: #ccc;
}

.ui-datepicker-next.ui-state-disabled:after {
    border-left-color: #ccc;
}

.ui-datepicker-title {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 16px;
}

.ui-datepicker-title .ui-datepicker-month {
    margin-right: 3px;
}

.ui-datepicker-calendar {
    background-color: #eee;
    text-align: right;
    width: 100%;
    border-collapse: collapse;
    line-height: 30px;
}

.ui-datepicker-calendar th {
    font-size: small;
    text-transform: uppercase;
    text-align: center;    
}

.ui-datepicker-calendar td {
    padding: 0 4px 0 0;
}

.ui-datepicker-calendar a {
    text-decoration: none;
    color: #444;
}

.ui-datepicker-calendar .ui-datepicker-week-end {
    color: #DDC000;
}

.ui-datepicker-calendar .ui-state-default {
    display: block;
    width: 30px;
    line-height: 26px;
    padding: 0 4px 0 0;
    border-radius: 2px;
}

.ui-datepicker-calendar .ui-state-disabled {
    color: #aaa;
}

.ui-datepicker-calendar .ui-state-hover {
    background-color: #ccc;
}

.ui-datepicker-calendar .ui-state-highlight {
    color: #DDC000;
    font-weight: bold;
}

.ui-datepicker-calendar .ui-state-active {
    background-color: #DDC000;
    color: #fff;
}

@media (max-width: 768px) {
    /*
    .ui-datepicker {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    box-shadow: 0 1px 12px rgba(0,0,0,.2), 0 0 0 768px rgba(0,0,0,.5);
}*/
}